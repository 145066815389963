module.exports = {
	env: {
		mode: 'stage',
		packageName: 'it.dietasocial.web',
		forceProduction: true,
                freeRoutes: true,
		mandatoryInstall: false,
		stage: {
			url: 'https://stage.dietasocial.it',
			node: 'https://api.dietasocial.it:3001',
			app: 'https://sandbox.dietasocial.it',
		},
	},
}

